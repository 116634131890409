import { AppState } from "@/lib/Store";
import { IMessage, ISetting } from "@/share/interfaces/ISetting";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { HYDRATE } from "next-redux-wrapper";

type AcceptableWidgetType = "text" | "video" | "live-text" | "live-video";

interface IWidgetState {
  botId: string | null;
  type: AcceptableWidgetType | null;
  token?: string;
  appearance: ISetting | null;
  messages: IMessage[];
  configs: {
    isLiveWidget: boolean;
    isInDelayMode: boolean;
    shouldHideExpandButton: boolean;
  };
}

const initialState: IWidgetState = {
  botId: null,
  type: null,
  appearance: null,
  messages: [],
  configs: {
    isLiveWidget: false,
    isInDelayMode: false,
    shouldHideExpandButton: false,
  },
};

const widgetSlice = createSlice({
  name: "widget",
  initialState,
  reducers: {
    handleInitializeWidget: (
      state,
      action: PayloadAction<{
        initialData: { appearance: ISetting; messages: IMessage[] };
        botId: string;
        token: string | null;
      }>
    ) => {
      state.botId = action.payload.botId;
      state.appearance = action.payload.initialData.appearance;
      state.messages = action.payload.initialData.messages;
      state.configs = {
        isLiveWidget: action.payload.token ? true : false,
        isInDelayMode: action.payload.initialData.messages.find(
          (item) => item.data?.type === "delay"
        )
          ? true
          : false,
        shouldHideExpandButton: false,
      };
    },
    addMessage: (
      state,
      action: PayloadAction<{
        messages: IMessage[];
        clientSessionId: string;
      }>
    ) => {
      state.messages = [...state.messages, ...action.payload.messages];
      if (state.appearance) {
        state.appearance.clientSessionId = action.payload.clientSessionId;
      }
    },
    handleStartDelayMode: (state, action: PayloadAction<boolean>) => {
      state.configs.isInDelayMode = action.payload;
    },
    handleToggleExpandButtonShow: (state, action: PayloadAction<boolean>) => {
      state.configs.shouldHideExpandButton = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(
      HYDRATE.toString(),
      (state, action: PayloadAction<AppState>) => {
        return {
          ...state,
          ...action.payload.widget,
        };
      }
    );
  },
});

export const {
  handleInitializeWidget,
  addMessage,
  handleStartDelayMode,
  handleToggleExpandButtonShow,
} = widgetSlice.actions;

export default widgetSlice.reducer;

export const selectMessages = (state: AppState) => state.widget.messages;

export const selectLastMessage = (state: AppState) =>
  state.widget.messages[state.widget.messages.length - 1];

export const selectSetting = (state: AppState) => state.widget.appearance;

export const selectLatestInputConfig = (state: AppState) =>
  [...state.widget.messages]
    ?.reverse()
    ?.find((message) => message.config !== undefined);

export const selectConfigs = (state: AppState) => state.widget.configs;
