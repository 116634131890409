import {wrapper} from "@/lib/Store";
import type {AppProps} from "next/app";
import {Provider} from "react-redux";
import "./globals.css";
import Head from "next/head";

export default function App({Component, ...rest}: AppProps) {
    const {store, props} = wrapper.useWrappedStore(rest);

    return (
        <Provider store={store}>
            <Head>
                <link rel="preconnect" href="https://fonts.googleapis.com"/>
                <link rel="preconnect" href="https://fonts.gstatic.com"/>
                <link
                    href="https://fonts.googleapis.com/css2?family=Lexend:wght@400;700&display=swap"
                    rel="stylesheet"
                />
            </Head>
            <Component {...props.pageProps} />
        </Provider>
    );
}
